import api from '@/api/services/marketplace';
import React, { useEffect, useState } from 'react';
import CollectionCard2 from './CollectionCard2';
import styles from './index.module.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/pagination';
import { Pagination, Navigation, Scrollbar, A11y } from 'swiper/modules';

export interface MpCardListProps {}

const MpCardList: React.FunctionComponent<MpCardListProps> = () => {
  //   const t = useTranslations('marketPlace');
  // const { activeChain } = useStore();
  const [hasMore, setHasMore] = useState(true);
  const [data, setData] = useState<any>([]);
  const [isClient, setIsClient] = useState(false); // Check if rendering on the client

  useEffect(() => {
    setIsClient(true); // Set client-side rendering

    api.getCollectionList({}).then((res) => {
      setHasMore(!!res.data);
      let newArr = res.data;
      newArr = newArr?.map((item: any) => {
        return {
          id: item.id,
          name: item.title,
          link: `/collections/${item.id}`,
          imgs: [
            item.img1 == '' ? '/projects/BattleOfMars/BattleOfMars-4.webp' : item.img1,
            item.img2 == '' ? '/projects/BattleOfMars/BattleOfMars-1.webp' : item.img2,
            item.img3 == '' ? '/projects/BattleOfMars/BattleOfMars-2.webp' : item.img3,
            item.img4 == '' ? '/projects/BattleOfMars/BattleOfMars-3.webp' : item.img4,
          ],
        };
      });
      setData(newArr);
    });
  }, []);
  if (!isClient) {
    return null; // Render nothing on the server
  }
  return (
    <div className='px-4 py-6'>
      {/* Swiper Slider */}
      <Swiper
        modules={[Scrollbar, A11y, Navigation, Pagination]}
        spaceBetween={16}
        breakpoints={{
          360: { slidesPerView: 1, spaceBetween: 16 },
          720: { slidesPerView: 2, spaceBetween: 16 },
          1440: { slidesPerView: 3, spaceBetween: 16 },
        }}
        scrollbar={{ draggable: true }} // Draggable scrollbar
        className='h-full'
        navigation
        pagination={{
          dynamicBullets: true,
          clickable: true,
          renderBullet: (index, className) => `<span class="${className} ${styles.customPaginationBullet}"></span>`,
        }}
      >
        {data.map((item) => (
          <SwiperSlide key={item.id} className='h-full'>
            <CollectionCard2
              key={item.id}
              imgs={item.imgs}
              name={item.name}
              link={`https://marketplace.landrocker.io/${item.link}`}
              commingSoon={item.link === '#'}
              className='px-[3px] h-full'
              collectionId={item.id}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      {/* {hasMore ? (
        <MySlider
          itemPerRow={4}
          data={data}
          hideNextPrev
          renderSectionHeading={({ onClickPrev, onClickNext, showNext, showPrev }) => {
            return (
              <>
                <Heading
                  hasNextPrev
                  desc=''
                  onClickPrev={onClickPrev}
                  onClickNext={onClickNext}
                  disableNext={!showNext}
                  disablePrev={!showPrev}
                  // className="mb-12 lg:mb-14 text-neutral-900 dark:text-neutral-50"
                  className='mb-4'
                  fontClass='text-3xl md:text-4xl 2xl:text-3xl font-semibold'
                  // isCenter
                >
                  <div className='flex flex-col'>
                    {t('topCollections')}
                    <Typography size='s16ToS14' className='text-[#989899] font-normal'>
                      {t('heroDesc')}
                    </Typography>
                  </div>
                </Heading>
              </>
            );
          }}
          renderItem={(item) => {
            return (
              <MyCollectionCard
                key={item.id}
                imgs={item.imgs}
                name={item.name}
                link={item.link}
                commingSoon={item.link === '#'}
                className='px-[3px]'
              />
            );
          }}
        />
      ) : (
        <div className='text-center mt-12 lg:mt-16'>
          <p className='text-neutral-500 dark:text-neutral-400'>No more items to show</p>
        </div>
      )} */}
    </div>
  );
};

export default MpCardList;
