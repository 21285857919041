/* eslint-disable sonarjs/cognitive-complexity */
import React, { ReactNode, useEffect, useRef } from 'react';
import { isIOS } from 'react-device-detect';
import classNames from 'classnames';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import Image, { StaticImageData } from 'next/image';
import { Typography } from '@/components/Atoms';
import useTranslationStatus from '@/hooks/useTranslationStatus';
import useResponsive from '@/hooks/useResponsive';

type fontSizeType =
  | 's40ToS24'
  | 's36ToS22'
  | 's32ToS20'
  | 's28ToS18'
  | 's28ToS15'
  | 's26ToS16'
  | 's24ToS16'
  | 's22ToS14'
  | 's22ToS16'
  | 's20ToS16'
  | 's20ToS14'
  | 's18ToS14'
  | 's16ToS14'
  | 's16ToS12'
  | 's14ToS12'
  | 's12ToS12';
gsap.registerPlugin(ScrollTrigger);

export interface SectionContainerProps {
  title?: ReactNode;
  description?: ReactNode;
  backgroundImage?: StaticImageData | string;
  backgroundVideo?: string;
  contentPosition: 'left' | 'right';
  buttons?: ReactNode;
  imageUrl?: StaticImageData | string;
  height?: string;
  imageHeight?: string;
  secondContent?: ReactNode;
  hasAnimation?: boolean;
  mobileImage?: StaticImageData | string;
  tabletImage?: string;
  blackText?: boolean;
  bgGradient?: string;
  titleColor?: string;
  imageClasses?: string;
  subTitle?: string | React.ReactNode;
  imagePosition?: 'top' | 'bottom' | 'center';
  descColor?: string;
  titleStyle?: any;
  mt?: number;
  aboveTitle?: string;
  hasLine?: boolean;
  aboveTitleColor?: string;
  backgroundIosVideo?: string;
  backgroundPosition?: 'top' | 'center' | 'bottom' | 'left' | 'right'; // New prop for controlling background position
  mainContent?: ReactNode;
  details?: Array<StaticImageData>;
  detailBorderImage?: string;
  id?: string;
  className?: string;
  subTitleSize?: fontSizeType;
}

const SectionContainer: React.FunctionComponent<SectionContainerProps> = ({
  id,
  title,
  description,
  backgroundImage,
  backgroundVideo,
  backgroundIosVideo,
  contentPosition,
  buttons,
  imageUrl,
  height,
  imageHeight,
  secondContent,
  hasAnimation = true,
  mobileImage,
  tabletImage,
  blackText = false,
  bgGradient,
  titleColor,
  imageClasses,
  subTitle,
  imagePosition = 'center',
  descColor,
  titleStyle,
  mt = 40,
  aboveTitle,
  hasLine = false,
  aboveTitleColor,
  backgroundPosition = 'start',
  mainContent,
  details,
  detailBorderImage,
  className,
  subTitleSize = 's32ToS20',
}) => {
  const imageRef = useRef(null);
  const contentRef = useRef(null);
  const rightContentRef = useRef(null);
  const leftContentRef = useRef(null);
  const { isReady } = useTranslationStatus();

  const { isDesktop1024, isMobile } = useResponsive();

  useEffect(() => {
    // Animate floating image if necessary
    if (imageRef.current && hasAnimation) {
      gsap.to(imageRef.current, {
        y: '+=20',
        duration: 2,
        repeat: -1,
        yoyo: true,
        ease: 'power1.inOut',
      });
    }

    // Animate content when section comes into view
    // if (hasAnimation && !isMobile) {
    gsap.fromTo(
      contentPosition === 'left' ? rightContentRef.current : leftContentRef.current,
      { x: '-100%', opacity: 0 },
      {
        x: 0,
        opacity: 1,
        duration: 2,
        ease: 'power2.out',
        scrollTrigger: {
          trigger: contentRef.current,
          start: 'top 80%',
          toggleActions: 'play none none none',
        },
      },
    );

    gsap.fromTo(
      contentPosition === 'left' ? leftContentRef.current : rightContentRef.current,
      { x: '100%', opacity: 0 },
      {
        x: 0,
        opacity: 1,
        duration: 2,
        ease: 'power2.out',
        scrollTrigger: {
          trigger: contentRef.current,
          start: 'top 80%',
          toggleActions: 'play none none none',
        },
      },
    );
    // }

    return () => {
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, [hasAnimation, isMobile]);

  const mobile = mobileImage || backgroundImage;
  const backgroundSrc = !isDesktop1024 ? backgroundImage : mobile;

  return (
    <div
      id={id}
      ref={contentRef}
      className={`relative overflow-hidden px-4 sm:px-7 md:px-10 lg:px-16 xl:px-20 flex flex-col lg:flex-row items-center justify-start min-h-[500px] lg:min-h-max ${className} ${
        contentPosition === 'left' ? 'lg:flex-row-reverse' : ''
      } ${imagePosition === 'bottom' && 'pt-10 lg:pt-44'}`}
      style={{
        // height: '100%',
        minHeight: height,
      }}
    >
      {/* Background Video or Image */}
      {backgroundIosVideo && isIOS ? (
        <video autoPlay muted loop playsInline preload='none' className='absolute top-0 left-0 w-full h-full object-cover'>
          <source src={backgroundIosVideo} type='video/mp4' />
          Your browser does not support the video tag.
        </video>
      ) : backgroundVideo ? (
        <video autoPlay muted loop preload='none' className='absolute top-0 left-0 w-full h-full object-cover'>
          <source src={backgroundIosVideo} type='video/mp4' />
          Your browser does not support the video tag.
        </video>
      ) : (
        backgroundSrc && (
          <Image
            key={`background-${backgroundImage}`}
            src={backgroundSrc}
            layout='fill'
            objectFit='cover'
            objectPosition='center'
            alt='Background Image'
          />
        )
      )}

      {/* Content Section */}
      <div
        ref={leftContentRef}
        className={` flex flex-col justify-center lg:justify-center items-center lg:items-start flex-1 pb-0 w-full lg:pb-0 lg:text-left ${
          contentPosition === 'left' ? 'lg:pl-3 lg:pr-1 2xl:pl-20 2xl:pr-10' : 'lg:pr-3 lg:pl-1 2xl:pr-20 2xl:pl-10'
        }`}
        style={{ height: '100%' }}
      >
        {mainContent ? (
          <div className='z-10'>{mainContent}</div>
        ) : (
          <>
            {aboveTitle && (
              <Typography
                Component={'h2'}
                size='s22ToS16'
                style={{ color: aboveTitleColor || '#9E9E9E', ...titleStyle }}
                className={classNames(
                  `text-center lg:text-start mt-10 lg:mt-20 font-bold text-shadow-lg z-10 leading-2 text-[${aboveTitleColor || '#9E9E9E'}]`,
                )}
              >
                {isReady && aboveTitle}
              </Typography>
            )}
            <Typography
              Component={'h2'}
              size='s40ToS24'
              style={{ color: titleColor || blackText ? 'black' : 'white', ...titleStyle }}
              className={classNames('font-bold text-shadow-xl z-10 text-center lg:text-start')}
            >
              {isReady && title}
              {subTitle && (
                <Typography
                  Component={'p'}
                  size={subTitleSize}
                  style={{ zIndex: 1 }}
                  className={`text-center lg:text-start ${blackText ? 'text-black' : 'text-white'}`}
                >
                  {isReady && subTitle}
                </Typography>
              )}
            </Typography>
            <Typography
              size='s18ToS14'
              Component={'p'}
              style={{
                color: descColor || '#C9C5CA',
              }}
              className={classNames('z-10 mb-6 text-shadow-xl text-center lg:text-start')}
            >
              {isReady && description}
            </Typography>
            <div className='flex space-x-4 justify-center lg:justify-start'>{buttons}</div>
            <div className={classNames(`h-full w-full grid grid-cols-${details?.length || 3} gap-2 lg:gap-6`)}>
              {details?.map((detail, index) => (
                <div key={index} className='relative max-w-[220px] items-center'>
                  <div className=''>
                    <Image src={detail} alt='Detail Image' objectFit='contain' layout='responsive' className='' width={'100%'} height={'100%'} />
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>

      {/* Image or Additional Content */}
      {imageUrl && (
        <div
          ref={rightContentRef}
          className={`mt-[${mt / 4}px] -mb-2 relative flex-1 w-full h-full lg:mt-0 flex justify-center ${
            imagePosition === 'bottom' ? 'items-end' : imagePosition === 'top' ? 'items-start' : 'items-end'
          }`}
          style={{ height: imageHeight }}
        >
          <div className='relative'>
            <Image
              src={imageUrl}
              alt=''
              className={classNames(
                'absolute bottom-0 w-full min-w-[180px] min-h-[180px] lg:min-w-[250px] lg:min-h-[250px] 2xl:min-w-[330px] 2xl:min-h-[330px] object-cover lg:max-w-max',
                imageClasses,
              )}
            />
          </div>
        </div>
      )}
      {secondContent && <div className='flex-1 z-10 flex justify-center items-center mt-8 lg:mt-0'>{secondContent}</div>}
    </div>
  );
};

export default SectionContainer;
