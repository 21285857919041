import React, { useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useResponsive } from '@/hooks';
import mobileImage from '@/public/images/new-home/Cryptohunt-mob-bg.jpg';
import imageUrl from '@/public/images/new-home/crypto-hunt.webp';
import poster0 from '@/public/images/new-home/poster0.jpg';
import poster1 from '@/public/images/new-home/poster1.jpg';
import backgroundImg from '@/public/images/new-home/Cryptohunt-bg.webp';
import SectionContainer from '../../Molecules/SectionContainer';
import { Typography } from '@/components/Atoms';
import Link from 'next/link';

export interface CryptoHuntProps {}

const CryptoHunt: React.FunctionComponent<CryptoHuntProps> = () => {
  const { t } = useTranslation('newHome');
  const { isDesktop1024 } = useResponsive();

  return (
    <SectionContainer
      title={t('newHome:cryptoHunt.title')}
      subTitle={t('newHome:cryptoHunt.subTitle')}
      description={t('newHome:cryptoHunt.desc')}
      backgroundImage={backgroundImg}
      mobileImage={mobileImage}
      subTitleSize='s28ToS15'
      hasLine
      contentPosition='left'
      height={!isDesktop1024 ? '700px' : '400px'}
      buttons={
        <a
          href='https://play.landrocker.io/'
          target='_blank'
          rel='noopener noreferrer'
          className='gtm-crypto-section px-4 py-2 rounded-lg shadow-xl border border-[#63FEBC] bg-[#63FEBC] font-bold text-black button-hover-effect'
        >
          <Typography size='s20ToS16'>{t('newHome:cryptoHunt.playBtn')}</Typography>
        </a>
      }
      imageUrl={isDesktop1024 ? undefined : imageUrl}
      imageClasses='mt-[50px]'
    />
  );
};

export default CryptoHunt;
