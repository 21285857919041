import React, { useEffect, useRef, useState } from 'react';
import { Tooltip } from '@material-tailwind/react';
import gsap from 'gsap';
import { ImageProps } from 'next/image';
import Image from 'next/image';
import { Typography } from '@/components/Atoms';
import { useResponsive } from '@/hooks';
import AnimatedButton from '@/components/Molecules/AnimatedButton';

interface GameSectionProps {
  title: string;
  subtitle: string;
  description: string;
  buttonText: string;
  buttonLink: string;
  secondaryButtonText?: string;
  secondaryButtonLink?: string;
  image?: string;
  weapons: Array<{
    src: ImageProps['src'] | string;
    name: string;
  }>;
  video: string;
  textColor?: string;
  hasYGradient?: boolean;
  buttonTextColor?: string;
  name: string;
}

const GameSection: React.FC<GameSectionProps> = ({
  title,
  subtitle,
  description,
  buttonText,
  buttonLink,
  secondaryButtonText,
  secondaryButtonLink,
  video,
  weapons,
  textColor,
  hasYGradient,
  buttonTextColor,
  name,
}) => {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const imageRef = useRef(null);
  const { isMobile } = useResponsive();

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVideoLoaded(true);
    }, 1000);
    return () => clearTimeout(timer); // Clean up the timer
  }, []);

  useEffect(() => {
    // Animation for floating or rotating images
    if (imageRef.current && !isMobile) {
      const images = imageRef.current.querySelectorAll('div > div'); // Target each image container
      images.forEach((image, index) => {
        gsap.fromTo(
          image,
          {
            y: index % 2 === 0 ? '-10' : '10', // Start position: alternating up and down
            rotate: index % 2 === 0 ? '-5deg' : '5deg', // Start with a slight rotation
          },
          {
            y: index % 2 === 0 ? '10' : '-10', // End position: move in the opposite direction
            rotate: index % 2 === 0 ? '5deg' : '-5deg', // Rotate in the opposite direction
            duration: 2, // Duration of the animation
            repeat: -1, // Infinite loop
            yoyo: true, // Reverse the animation back to the original position
            ease: 'power1.inOut', // Smooth easing
            delay: index * 0.2, // Staggered delay for each image
          },
        );
      });
    }
  }, [isMobile]);

  return (
    <div className='flex flex-wrap flex-row items-center justify-between bg-[#000000] shadow-lg min-h-[75vh] pt-10 md:mt-0'>
      <div className='flex-1 text-center md:text-left mb-6 min-w-[340px] md:mb-0'>
        <Typography Component={'h2'} className={`text-[28px] md:text-[40px] xl:text-[50px] text-4xl font-bold mb-2 text-[${textColor || 'white'}]`}>
          {title}
        </Typography>
        <Typography Component={'p'} size='s28ToS18' className='text-2xl font-semibold mb-4'>
          {subtitle}
        </Typography>
        <Typography size='s16ToS14' Component={'p'} className='text-[#C9C5CA] mb-6'>
          {description}
        </Typography>
        <div className='flex gap-1 justify-center lg:justify-start' style={{ color: buttonTextColor || 'white' }}>
          {/* <a
            href={buttonLink}
            className={`inline-block px-3 py-2 xl:px-6 lg:py-3 font-semibold rounded-md bg-[${
              textColor || 'white'
            }] hover:opacity-80 transition-opacity gtm-download_now-${name.replace(' ', '_')}`}
          >
            {buttonText}
          </a> */}
          <AnimatedButton
            buttonLink={buttonLink}
            buttonText={buttonText}
            textColor={textColor}
            // Button background color (customizable)
            name='Download Now'
          />
          {secondaryButtonText && secondaryButtonLink && (
            <a
              href={secondaryButtonLink}
              className={`gtm-download-game-home inline-block px-3 py-2 xl:px-6 lg:py-3 font-semibold rounded-md bg-gray-800 text-white hover:opacity-80 transition-opacity gtm-home-${name.replace(
                ' ',
                '_',
              )}-secondary_button`}
            >
              {secondaryButtonText}
            </a>
          )}
        </div>
      </div>
      <div className='lg:flex-1 mx-auto flex justify-center items-center'>
        <div
          style={{
            maskImage: hasYGradient
              ? `
     linear-gradient(to bottom, transparent 4%, black 20%, black 80%, transparent 95%),
      linear-gradient(to right, transparent 4%, black 20%, black 80%, transparent 95%)
   `
              : 'linear-gradient(to right, transparent, black, black, transparent)',
            maskComposite: 'intersect',
            WebkitMaskComposite: 'intersect',
            maskSize: '100% 100%', // Ensures mask covers the whole video area
            WebkitMaskSize: '100% 100%', // For Webkit browsers
          }}
          // className='w-fit'
        >
          {isVideoLoaded && (
            <video
              preload='none'
              autoPlay
              loop
              muted
              playsInline
              src={video}
              style={{
                width: '100%',
                height: '100%', // Make the video height responsive
                maxHeight: '555px', // Limit the maximum height to maintain aspect ratio
                borderRadius: '10px',
              }}
            />
          )}
        </div>
      </div>
      <div className='flex-1  xl:max-w-[1/3]'>
        <div ref={imageRef} className='flex flex-wrap justify-center lg:grid lg:grid-cols-2 gap-1'>
          {weapons.map((weapon, index) => (
            <Tooltip
              key={index}
              content={weapon.name}
              className='!bg-[#404040]'
              animate={{
                mount: { scale: 1, y: 0 },
                unmount: { scale: 0, y: 25 },
              }}
              placeholder=''
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            >
              <div className='max-w-xs mb-7 w-20 md:w-32 2xl:w-40'>
                <Image placeholder='blur' src={weapon.src} alt={`Weapon ${index + 1}`} />
              </div>
            </Tooltip>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GameSection;
