import React, { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import imageUrl from '@/public/images/new-home/4.png';
import backgroundImg from '@/public/images/new-home/history-web.png';
import SectionContainer from '../../Molecules/SectionContainer';

export interface HistorySectionProps {}

const HistorySection: React.FunctionComponent<HistorySectionProps> = () => {
  const { t } = useTranslation('newHome');
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  return (
    <SectionContainer
      title={t('newHome:history.title')}
      description={t('newHome:history.description')}
      backgroundImage={backgroundImg}
      hasAnimation={false}
      contentPosition='left'
      buttons={null}
      descColor='white'
      imageUrl={imageUrl}
      imageClasses='bottom-0 h-full'
      imagePosition='bottom'
      mt={150}
      titleStyle={{
        background: 'linear-gradient(90deg, #FFFFFF, #A5A7B8)' /* Adjust the colors and angle as needed */,
        WebkitTextFillColor: 'transparent',
        WebkitBackgroundClip: 'text',
        backgroundClip: 'text',
        textFillColor: 'transparent',
      }}
    />
  );
};

export default HistorySection;
