import React, { useRef } from 'react';
import { useTranslation } from 'next-i18next';
import armedDroneImage from '@/public/images/new-home/battle/armed-drone.png';
import grenadeLauncherImage from '@/public/images/new-home/battle/granade-launcher.png';
import untitledImage from '@/public/images/new-home/battle/untitled.png';
import wolfLvlImage from '@/public/images/new-home/battle/wolf-LVL.png';
import GameSection from './GameSection';

export interface GameModeSectionProps {}

const GameModeSection: React.FunctionComponent<GameModeSectionProps> = () => {
  const { t } = useTranslation('newHome');
  const sectionRef = useRef(null);

  return (
    <div ref={sectionRef} className='px-4 sm:px-7  md:px-10 lg:px-16 xl:px-20 bg-[#000000]'>
      <div className=' md:overflow-x-auto lg:overflow-x-clip md:flex-row flex-col justify-between gap-2 lg:gap-5'>
        <GameSection
          name='The Battle'
          title={t('newHome:battle.title')}
          subtitle={t('newHome:battle.subTitle')}
          description={t('newHome:battle.text')}
          buttonText={t('newHome:battle.button')}
          buttonLink='/game'
          video='images/new-home/game/fighter.mp4'
          weapons={[
            { src: grenadeLauncherImage, name: 'Fox' },
            { src: untitledImage, name: 'Companion' },
            { src: armedDroneImage, name: 'Jet Pack' },
            { src: wolfLvlImage, name: 'Lion' },
          ]}
          textColor='#E25709'
        />
      </div>
    </div>
  );
};

export default GameModeSection;
