import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import HomeGifCard from '@/components/Molecules/HomeGifCard';

export interface GifCardsProps {}

const GifCards: React.FunctionComponent<GifCardsProps> = () => {
  const { t } = useTranslation('newHome');
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  const cardData = [
    {
      id: 1,
      title: t('newHome:gifs.command.title'), // Reading title from JSON
      description: t('newHome:gifs.command.text'), // Reading description from JSON
      buttons: [
        { label: t('newHome:gifs.command.mine'), href: 'https://example.com/mine' },
        { label: t('newHome:gifs.command.librate'), href: 'https://example.com/liberate' },
        { label: t('newHome:gifs.command.inviteFriends'), href: 'https://example.com/invite' },
        { label: '...', href: 'https://example.com/more' },
      ],
      gif: 'images/new-home/gif/command.gif',
      video: 'images/new-home/webm/command.webm',
    },
    {
      id: 2,
      title: t('newHome:gifs.gear.title'), // Reading title from JSON
      description: t('newHome:gifs.gear.text'), // Reading description from JSON
      buttons: [
        { label: t('newHome:gifs.gear.rover'), href: 'https://example.com/rover' },
        { label: t('newHome:gifs.gear.weapon'), href: 'https://example.com/weapon' },
        { label: t('newHome:gifs.gear.fuel'), href: 'https://example.com/fuel' },
        { label: '...', href: 'https://example.com/morse' },
      ],
      gif: 'images/new-home/gif/gear.gif',
      video: 'images/new-home/webm/gear.webm',
    },
    {
      id: 3,
      title: t('newHome:gifs.lrt.title'), // Reading title from JSON
      description: t('newHome:gifs.lrt.text'), // Reading description from JSON
      buttons: [
        { label: 'LRT', href: 'https://example.com/lrt' }, // You might need to add translations if these are dynamic too
        { label: t('newHome:gifs.lrt.element'), href: 'https://example.com/element' },
        { label: 'NFT', href: 'https://example.com/nft' }, // Similarly, add translations for static text if necessary
        { label: '...', href: 'https://example.com/more' },
      ],
      gif: 'images/new-home/gif/lrt.gif',
      video: 'images/new-home/webm/lrt.webm',
    },
  ];

  return (
    <>
      {isClient && (
        <div className='min-h-[80vh] pt-20 pb-28 px-4 sm:px-7 md:px-10 lg:px-16 xl:px-20 p-4 bg-[#000000] flex flex-col justify-center'>
          <div className='flex flex-wrap items-start justify-around'>
            {cardData.map((card) => (
              <HomeGifCard key={card.id} {...card} />
            ))}
          </div>
          <Link href='/how-to-play-mine-earn' passHref>
            <a>
              <div className='gtm-learn-gif-section w-[260px] h-[45px] button-hover-effect flex justify-center items-center rounded-lg mx-auto mt-10 lg:mt-20 border cursor-pointer'>
                {t('newHome:gifs.learnMore')} {/* Reading 'Learn More' text from JSON */}
              </div>
            </a>
          </Link>
        </div>
      )}
    </>
  );
};

export default GifCards;
