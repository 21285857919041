import React from 'react';
import { useTranslation } from 'next-i18next';
import imageUrl from '@/public/images/new-home/6.png';
import firstDetail from '@/public/images/new-home/detail/4.png';
import secondDetail from '@/public/images/new-home/detail/5.png';
import thirdDetail from '@/public/images/new-home/detail/6.png';
import SectionContainer from '../../Molecules/SectionContainer';

export interface NetherexSectionProps {}

const NetherexSection: React.FunctionComponent<NetherexSectionProps> = () => {
  const { t } = useTranslation('newHome');

  return (
    <SectionContainer
      title={t('newHome:netherex.title')}
      // subTitle='to defeat the'
      description={t('newHome:netherex.description')}
      backgroundIosVideo='images/new-home/netherex-ios.mp4'
      backgroundVideo='images/new-home/netherex-video.webm'
      mobileImage='images/new-home/netherex-mob.png'
      contentPosition='left'
      imageUrl={imageUrl}
      hasAnimation={false}
      imagePosition='bottom'
      descColor='#ECE7BE'
      details={[firstDetail, secondDetail, thirdDetail]}
      detailBorderImage='/images/new-home/netherex-border.png'
      titleStyle={{
        background: 'linear-gradient(90deg, #999855, #B17A3C)' /* Adjust the colors and angle as needed */,
        WebkitTextFillColor: 'transparent',
        WebkitBackgroundClip: 'text',
        backgroundClip: 'text',
        textFillColor: 'transparent',
      }}
      imageClasses='bottom-0 h-full'
    />
  );
};

export default NetherexSection;
