import React from 'react';
import { useTranslation } from 'next-i18next';
import firstDetail from '@/public/images/new-home/detail/1.png';
import secondDetail from '@/public/images/new-home/detail/2.png';
import thirdDetail from '@/public/images/new-home/detail/3.png';
import imageUrl from '@/public/images/new-home/etherian.png';
import SectionContainer from '../../Molecules/SectionContainer';

export interface EtheriansSectionProps {}

const EtheriansSection: React.FunctionComponent<EtheriansSectionProps> = () => {
  const { t } = useTranslation('newHome');

  return (
    <SectionContainer
      title={t('newHome:etherian.title')}
      description={t('newHome:etherian.description')}
      titleColor='#2AADFF'
      descColor='#D0DFE4'
      backgroundIosVideo='images/new-home/etherian-web.mp4'
      backgroundVideo='images/new-home/etherian-video.webm'
      imageUrl={imageUrl}
      contentPosition='right'
      hasAnimation={false}
      imagePosition='bottom'
      details={[firstDetail, secondDetail, thirdDetail]}
      detailBorderImage='/images/new-home/etherian-border.png'
      titleStyle={{
        background: 'linear-gradient(90deg, #E0D6D5, #7B9DA8)' /* Adjust the colors and angle as needed */,
        WebkitTextFillColor: 'transparent',
        WebkitBackgroundClip: 'text',
        backgroundClip: 'text',
        textFillColor: 'transparent',
      }}
      imageClasses='bottom-0 h-full'
    />
  );
};

export default EtheriansSection;
