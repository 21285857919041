import React, { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import mobileImage from '@/public/images/new-home/telegram-mob.png';
import imageUrl from '@/public/images/new-home/telegram.png';
import backgroundImage from '@/public/images/telegramWeb.webp';
import SectionContainer from '../../Molecules/SectionContainer';
import { Typography } from '@/components/Atoms';
import Link from 'next/link';
import useTranslationStatus from '@/hooks/useTranslationStatus';

export interface TelegramSectionProps {}

const TelegramSection: React.FunctionComponent<TelegramSectionProps> = () => {
  const { t } = useTranslation('newHome');
  const [isClient, setIsClient] = useState(false);
  const { isReady } = useTranslationStatus();

  useEffect(() => {
    setTimeout(() => {
      setIsClient(true);
    }, 100);
  }, []);

  return (
    <SectionContainer
      id='telegram-section'
      titleColor='#37BCDE'
      title={isReady && t('newHome:telegram.above')}
      description={
        <div className='flex flex-col'>
          <span>{isReady && t('newHome:telegram.description')}</span>
        </div>
      }
      backgroundImage={backgroundImage}
      mobileImage={mobileImage}
      hasAnimation={false}
      contentPosition='left'
      buttons={
        <div className='flex-row gap-2 mb-2 lg:mb-0 hidden lg:flex'>
          {/* External Link */}
          <a
            href='https://t.me/landrocker_miner_bot'
            target='_blank'
            rel='noopener noreferrer'
            className='gtm-launch-telegram-section px-4 py-2 rounded-lg shadow-xl border border-[#63FEBC] bg-[#63FEBC] font-bold text-black button-hover-effect'
          >
            <Typography size='s20ToS16'>{isReady && t('newHome:telegramHero.launch')}</Typography>
          </a>
          {/* Internal Link */}
          <Link href='/telegram' passHref>
            <a className='gtm-learn-telegram-section px-4 py-2 bg-white rounded-lg border border-[#63FEBC] text-black font-bold text-shadow-xl shadow-xl button-hover-effect'>
              <Typography size='s20ToS16'>{isReady && t('newHome:gifs.learnMore')}</Typography>
            </a>
          </Link>
        </div>
      }
      descColor='white'
      imageUrl={imageUrl}
      imageClasses='bottom-0 h-full'
      imagePosition='bottom'
      mt={150}
      backgroundPosition='bottom'
      titleStyle={{ color: 'white', marginTop: 3 }}
    />
  );
};

export default TelegramSection;
