import React from 'react';
import styles from './index.module.scss';

export interface CustomDividerProps {}

const CustomDivider: React.FunctionComponent<CustomDividerProps> = () => {
  return <div className={styles.gradient_audit_line} />;
};

export default CustomDivider;
