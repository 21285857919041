import { Typography } from '@/components/Atoms';
import { useResponsive } from '@/hooks';
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

const BitcoinSection = () => {
  const [isDesktopView, setIsDesktopView] = useState(false);
  const isDesktop = useMediaQuery({
    query: '(min-width: 1024px)',
  });

  useEffect(() => {
    setIsDesktopView(isDesktop);
  }, [isDesktop]);

  return (
    <div
      className='px-4 sm:px-7 md:px-10 lg:px-16 xl:px-20 relative w-full h-[90vh] overflow-hidden bg-[url(/images/new-home/bitcoin-banner-mobile.webp)] lg:bg-[url(/images/new-home/bitcoin-banner.webp)] 
    bg-bottom lg:bg-center flex items-center justify-center lg:justify-start bg-no-repeat bg-cover'
    >
      <div className='flex flex-col items-center lg:items-start'>
        <div className='flex flex-col text-center lg:block'>
          <Typography className='text-[34px] sm:text-[40px] md:text-[44px] lg:text-[54px] xl:text-[64px] text-[#ffc830]' isBold>
          Find the hidden BTC
          </Typography>
          <Typography size='s28ToS18' className='lg:text-white text-[#FFC629] leading-3'>
            with just a few clicks!
          </Typography>
        </div>
        <div className='flex h-fit text-center lg:text-start'>
          <div className='h-auto bg-[#30f7bb7d] w-[2.8px] mr-2 hidden lg:flex'></div>
          <div
            className='lg:p-[21px] xl:min-w-[800px] rounded-md flex flex-col mt-5 lg:mt-0'
            style={{ background: !isDesktopView ? 'transparent' : 'linear-gradient(90deg, rgba(48,247,186,0.2) 0%, rgba(48,247,186,0) 49%)' }}
          >
            <Typography size='s26ToS16'>Fully transparent on</Typography>
            <Typography className='text-[#30F7BA] text-[26px] sm:text-[26px] md:text-[30px] lg:text-[36px] xl:text-[46px] lg:mb-4' isBold>
              the blockchain.
            </Typography>
            <div className='flex lg:flex-col items-center lg:items-start gap-1 lg:gap-0'>
              <Typography className='leading-7 xl:leading-5 text-[15px] sm:text-[20px] md:text-[26px] lg:text-[30px] xl:text-[36px]'>
                No one knows the BTC location
              </Typography>
              <Typography className='xl:text-[46px] lg:text-[36px] md:text-[26px] sm:text-[20px] text-[15px] text-[#30F7BA] lg:text-white lg:font-bold'>
                even <Typography className='text-[#30F7BA]'>us!</Typography>
              </Typography>
            </div>
          </div>
        </div>
        <div className='flex flex-col mt-4 lg:mt-2 text-center lg:text-start'>
          <Typography size='s26ToS16'>
            Earn{' '}
            <Typography className='text-[#FFC629]' size='s36ToS22'>
              10%
            </Typography>{' '}
            of your friends' mining fees,
          </Typography>
          <Typography className='text-[#FFC629] leading-6' size='s36ToS22' isBold>
            plus an extra Bitcoin reward!
          </Typography>
          <Typography size='s26ToS16' className='mt-2'>
            If your friends win, so do you!
          </Typography>
          <Typography className='text-white mt-4' size='s20ToS16'>
            Coming Soon ...
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default BitcoinSection;
