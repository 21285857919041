/* eslint-disable react/jsx-no-undef */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/no-identical-functions */
import React, { useEffect, useState } from 'react';
import { Tooltip } from '@material-tailwind/react';
import Image from 'next/image';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import api from '@/api/services/index';
import { Typography } from '@/components/Atoms';
import styles from './InfoCardContainer.module.scss';
import { detectWebGLSupport } from '@/utils/isWebglSupported';
import ThreeDSvgTwo from '@/components/Svg/360Svg';
import dynamic from 'next/dynamic';

const GLBModel = dynamic(() => import('@/components/marketPlaceDetail/GLBModel/GLBModel'));

export interface InfoCardContainerProps {
  backgroundImage?: string; // Optional background image for the section, default to transparent
  linkText?: string;
  linkUrl?: string;
}

const InfoCardContainer: React.FunctionComponent<InfoCardContainerProps> = ({ backgroundImage, linkText, linkUrl }) => {
  const { t } = useTranslation('newHome');
  const [nftData, setNftData] = useState<any>([]);
  const [selectedWeapon, setSelectedWeapon] = useState<any>(null); // State for the selected weapon
  const { webgl1, webgl2 } = detectWebGLSupport();

  // Fetch the data
  const getData = () => {
    api
      .assetDetail({ category: '07745191-b758-4453-8eae-33bdd79aa904', offset: 0, limit: 14 })
      .then((res) => {
        const data = res.data.Data;
        setNftData(data); // Set the fetched data to the state
        if (data) {
          setSelectedWeapon(data[0]); // Set the first item as the default selected weapon
        }
      })
      .catch((error) => console.error('Error fetching data:', error));
  };

  // Fetch data on component mount
  useEffect(() => {
    getData();
  }, []);

  // Function to handle click on a slide and update selected weapon
  const onSlideClick = (weapon: any) => {
    if (weapon && weapon.PrifixAddress && weapon.Model) {
      setSelectedWeapon(weapon); // Update selected weapon when a slide is clicked
    }
  };

  // Function to truncate the description if it's more than 200 characters
  const truncateDescription = (description: string) => {
    if (description && description.length > 200) {
      return description.substring(0, 200) + '...';
    }
    return description;
  };

  return (
    <div className='px-4 sm:px-7 md:px-10 lg:px-16 xl:px-20 no-repeat w-full p-6 md:py-20 object-cover'>
      <div className='hidden lg:flex flex-col lg:flex-row lg:px-16 lg:py-14 justify-between items-center rounded-[16px] border-[#1E2727]'>
        <div className='w-full lg:w-1/2 text-center lg:text-left'>
          {selectedWeapon ? (
            <>
              <Typography size='s40ToS24' Component={'h2'} className='font-bold text-white'>
                {selectedWeapon.Name || 'Default Title'}
              </Typography>
              <div className='flex flex-col w-full items-start justify-start lg:h-56 xl:h-48 overflow-y-auto lg:mt-4 pt-4 xl:pt-4'>
                <Typography size='s18ToS14' Component={'p'} className=' text-white'>
                  {truncateDescription(selectedWeapon.Description) || 'Default Description'}
                  {selectedWeapon.Description && selectedWeapon.Description.length > 200 && (
                    <Link passHref href={`https://marketplace.landrocker.io/nft-detail/${selectedWeapon.Id}`}>
                      <a>
                        <Typography
                          size='s16ToS14'
                          className='underline text-[#47FEFF] rounded-md w-fit px-4 py-2 mb-8 md:mb-16 cursor-pointer gtm-home-weapons-readMore'
                        >
                          {t('newHome:weapons.readMore')}
                        </Typography>
                      </a>
                    </Link>
                  )}
                </Typography>
              </div>
              <div className='w-full flex h-full flex-col md:flex-row justify-start items-center lg:space-x-6 xl:space-x-8 mt-9'>
                {selectedWeapon?.Property?.map((prop: any, index: number) => (
                  <React.Fragment key={index}>
                    <div className='text-center flex-col justify-center items-center flex'>
                      <Typography size='s16ToS12' className='text-[#47FEFF] capitalize'>
                        {prop.name}
                      </Typography>
                      <Typography size='s16ToS12' className='text-white font-bold'>
                        {prop.value} {prop.based}
                      </Typography>
                    </div>
                    {index < selectedWeapon.Property.length - 1 && <div className='w-[2px] min-h-10 bg-[#363843]'></div>}
                  </React.Fragment>
                ))}
              </div>
            </>
          ) : (
            <div>Loading...</div>
          )}
          <div className='overflow-hidden hidden lg:flex mt-10 w-full justify-start relative'>
            <Swiper
              modules={[Pagination]}
              pagination={{
                dynamicBullets: true,
                clickable: true,
                renderBullet: (index, className) => `<span class="${className} ${styles.customPaginationBullet}"></span>`,
              }}
              loop
              breakpoints={{
                1024: { slidesPerView: 3, spaceBetween: 50 },
                1440: { slidesPerView: 4, spaceBetween: 20 },
              }}
            >
              {nftData.map((weapon: any, index: number) => (
                <SwiperSlide key={weapon.id} className='' onClick={() => onSlideClick(weapon)}>
                  <Tooltip
                    key={index}
                    content={weapon.Name}
                    className='!bg-[#404040] w-fit '
                    animate={{
                      mount: { scale: 1, y: 0 },
                      unmount: { scale: 0, y: 25 },
                    }}
                    placeholder=''
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                  >
                    <div className='lg:h-44 xl:h-52 w-fit lg:mr-0 xl:mr-10 object-contain cursor-pointer'>
                      {/* {webgl1 && webgl2 ? (
                        <GLBModelWithAnimation url={weapon.PrifixAddress + weapon.Model} />
                      ) : ( */}
                      <Image
                        src={weapon.PrifixAddress + weapon.Image}
                        alt='weapon'
                        width={200}
                        height={200}
                        loading={'lazy'}
                        placeholder='blur'
                        blurDataURL='/images/NFT.svg'
                        className='rounded-xl'
                      />
                      {/* )} */}
                    </div>
                  </Tooltip>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
        <div className='lg:w-2/3 flex flex-col items-center'>
          <div className='mb-4 w-full h-[460px] object-contain flex justify-center items-center relative'>
            {selectedWeapon && webgl1 && webgl2 ? (
              <>
                <div className='absolute bottom-8 left-1/2 z-10 -translate-x-1/2 -translate-y-1/2'>
                  <ThreeDSvgTwo />
                </div>
                <GLBModel hasGlbBase={false} url={selectedWeapon?.PrifixAddress + selectedWeapon?.Model} enableZoom={false} />
              </>
            ) : (
              <Image src={selectedWeapon?.PrifixAddress + selectedWeapon?.Image} alt='weapon' width={400} height={400} />
            )}
          </div>
          {linkUrl && (
            <Link href={linkUrl} passHref className='cursor-pointer'>
              <a>
                <div className='border border-[#47FEFF] text-[#47FEFF] button-hover-effect py-2 px-4 rounded-lg cursor-pointer'>{linkText}</div>
              </a>
            </Link>
          )}
        </div>
      </div>

      {/* Mobile View */}
      <div className='flex lg:hidden flex-col lg:flex-row justify-between items-center rounded-lg'>
        <div className='w-full lg:w-1/3 p-6 text-left justify-center flex flex-col'>
          <div className='mb-4 w-full h-[300px] md:h-[385px] object-contain justify-center  flex'>
            {selectedWeapon && <Image src={selectedWeapon.PrifixAddress + selectedWeapon.Image} alt='weapon' width={400} height={300} />}
          </div>
          <div className=' overflow-hidden'>
            <Swiper
              loop
              modules={[Pagination]}
              pagination={{
                dynamicBullets: true,
                clickable: true,
                renderBullet: (index, className) => `<span class="${className} ${styles.customPaginationBullet}"></span>`,
              }}
              breakpoints={{
                360: { slidesPerView: 2, spaceBetween: 30 },
                480: { slidesPerView: 2, spaceBetween: 30 },
                640: { slidesPerView: 3, spaceBetween: 30 },
                768: { slidesPerView: 3, spaceBetween: 30 },
              }}
            >
              {nftData.map((weapon: any, index: number) => (
                <SwiperSlide key={weapon.id} className='' onClick={() => onSlideClick(weapon)}>
                  <Tooltip
                    key={index}
                    content={weapon.Name}
                    className='!bg-[#404040] w-fit '
                    animate={{
                      mount: { scale: 1, y: 0 },
                      unmount: { scale: 0, y: 25 },
                    }}
                    placeholder=''
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                  >
                    <div title={weapon.Name} className='w-fit h-full object-contain  cursor-pointer'>
                      <Image src={weapon.PrifixAddress + weapon.Image} alt='weapon' width={200} height={200} />
                    </div>
                  </Tooltip>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>

          {selectedWeapon && (
            <>
              <Typography Component={'h2'} className='text-[50px] font-bold text-white mb-4'>
                {selectedWeapon.Name || 'Default Title'}
              </Typography>
              <div className='flex flex-col pr-2 w-full items-start justify-start h-52 md:h-40 lg:h-60 xl:h-52 overflow-y-auto lg:mt-4 pt-4 xl:pt-4'>
                <Typography size='s18ToS14' Component={'p'} className=' text-white'>
                  {truncateDescription(selectedWeapon.Description) || 'Default Description'}
                  {selectedWeapon.Description && selectedWeapon.Description.length > 200 && (
                    <Link passHref href={`https://marketplace.landrocker.io/nft-detail/${selectedWeapon.Id}`}>
                      <a>
                        <Typography size='s16ToS14' className='text-[#47FEFF] rounded-md w-fit px-4 py-2 mb-8 md:mb-16 cursor-pointer'>
                          {t('newHome:weapons.readMore')}
                        </Typography>
                      </a>
                    </Link>
                  )}
                </Typography>
              </div>
              <div className='flex w-full h-full  flex-row justify-start items-center space-x-2 md:space-x-5 space-y-0 my-2'>
                {selectedWeapon?.Property?.map((prop: any, index: number) => (
                  <React.Fragment key={index}>
                    <div className='text-center flex-col  justify-center items-center flex'>
                      <Typography size='s16ToS12' className='text-[#47FEFF] capitalize'>
                        {prop.name}
                      </Typography>
                      <Typography size='s16ToS12' className='text-white font-bold'>
                        {prop.value} {prop.based}
                      </Typography>
                    </div>
                    {index < selectedWeapon.Property.length - 1 && <div className='w-[2px] min-h-10 bg-[#363843]'></div>}
                  </React.Fragment>
                ))}
              </div>
            </>
          )}
        </div>
        <div className='lg:w-2/3 flex flex-col items-center mb-4'>
          {linkUrl && (
            <Link href={linkUrl} passHref className='cursor-pointer'>
              <a>
                <div className='border border-[#47FEFF] text-[#47FEFF] py-2 px-4 rounded-lg cursor-pointer gtm-home-weapons-go_to_marketplace'>
                  {linkText}
                </div>
              </a>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default InfoCardContainer;
