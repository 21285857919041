import React, { useEffect, useState } from 'react';
import { isIOS } from 'react-device-detect';
import classNames from 'classnames';
import { Typography } from '@/components/Atoms';
import styles from './index.module.scss';

export interface HomeGifCardProps {
  title: string;
  description: string;
  gif: string;
  video: string;
  buttons?: Array<{ label: string; href: string }>;
  className?: string;
}

const HomeGifCard: React.FunctionComponent<HomeGifCardProps> = ({ description, title, gif, video, buttons, className }) => {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsVideoLoaded(true);
    }, 1000);
  }, []);
  return (
    <div className={classNames(styles.card, 'max-w-[320px] xl:max-w-[400px]', className)}>
      <div className={styles.icon}>
        {!isIOS ? (
          isVideoLoaded && (
            <video
              preload='none'
              autoPlay
              loop
              muted
              playsInline
              className={styles.icon}
              src={video}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                borderRadius: '10px',
              }}
            />
          )
        ) : (
          <img src={gif} alt={`${title} ${styles.icon}`} />
        )}
      </div>
      <Typography size='s26ToS16' className='font-bold'>
        {title}
      </Typography>
      <div className={styles['button-group']}>
        {buttons &&
          buttons.map((button, index) => (
            <button key={index} className={styles['card-button']}>
              {button.label}
            </button>
          ))}
      </div>
      <Typography Component={'p'} size='s18ToS14' className='text-[#C9C5CA] mt-5'>
        {description}
      </Typography>
    </div>
  );
};

export default HomeGifCard;
