import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames'; // First, install classnames if not already installed
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import api from '@/api/services';
import CustomSkeleton from '@/components/Common/CustomSkeleton';
import LoadingBalls from '@/components/Common/LoadingBalls';
import { ArrowLeftSvg, ArrowRightSvg } from '@/components/Svg';
import Wrapper from '@/components/TokenSale/Wrapper';
import { IDailyNeewsVideosData } from '@/types/TokenSale/res';
import VideosOfDailyNews from '../VideosOfInfluencerLayout/components/VideosOfDailyNews';
import styles from './DailyNews.module.scss';
import useData from './useData';

export interface HomeDailyNewsProps {}

const HomeDailyNews: React.FunctionComponent<HomeDailyNewsProps> = () => {
  const { t } = useTranslation('home');
  const [data, setData] = useState<IDailyNeewsVideosData[]>();
  const { breakpoints } = useData();
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  const swiperRef = useRef(null);

  useEffect(() => {
    api.getAllDailyNews().then((res) => {
      setData(res.data?.result?.items);
    });
  }, []);

  const sortedData = [...(data || [])].sort((a, b) => b.CreateAt - a.CreateAt);

  if (!data) {
    return <CustomSkeleton width='100%' height='300px' />;
  }

  const handlePrevClick = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNextClick = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  return (
    <Wrapper className=''>
      <div className='font-bold text-center text-[30px] lg:text-[47px]'>{t('home:news.title')}</div>
      <div className='text-[20px] lg:text-[24px] text-[#808080] text-center'>{t('home:news.description')}</div>
      <div className='mt-10 px-4'>
        <Swiper
          className='mySwiper'
          modules={[Autoplay, Pagination, Navigation]}
          onInit={(swiper) => {
            setIsBeginning(swiper.isBeginning);
            setIsEnd(swiper.isEnd);
          }}
          onReachBeginning={() => setIsBeginning(true)}
          onReachEnd={() => setIsEnd(true)}
          onFromEdge={() => {
            setIsBeginning(false);
            setIsEnd(false);
          }}
          pagination={{
            dynamicBullets: true,
            clickable: true,
          }}
          navigation={{
            // eslint-disable-next-line sonarjs/no-duplicate-string
            prevEl: `.${styles['swiper-button-prev']}`,
            // eslint-disable-next-line sonarjs/no-duplicate-string
            nextEl: `.${styles['swiper-button-next']}`,
          }}
          breakpoints={breakpoints}
          ref={swiperRef}
        >
          {sortedData.map((item) => (
            <SwiperSlide key={item.Id}>
              <VideosOfDailyNews item={item} />
            </SwiperSlide>
          ))}
        </Swiper>
        {!isBeginning && (
          <div className={classnames(styles['swiper-button-prev'], 'swiper-button-prev')} onClick={handlePrevClick}>
            <ArrowLeftSvg color='black' />
          </div>
        )}
        {!isEnd && (
          <div className={classnames(styles['swiper-button-next'], 'swiper-button-next')} onClick={handleNextClick}>
            <ArrowRightSvg color='black' />
          </div>
        )}
      </div>
      <Link href='/breaking-news' passHref>
        <a>
          <div className='w-[260px] h-[45px] button-hover-effect flex justify-center items-center rounded-lg mx-auto mt-10 lg:mt-20 border cursor-pointer'>
            {t('home:news.show_more')}
          </div>
        </a>
      </Link>
    </Wrapper>
  );
};

export default HomeDailyNews;
