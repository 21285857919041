function sortDate(a, b) {
  return a?.json?.props[0].order - b?.json?.props[0].order;
}

export const homePageData = () => {
  let uniqueId = 1;
  const temp: any = []; // if (!temp) {
  temp.push({
    id: uniqueId++,
    created_at: '2023-10-01T15:16:46.801Z',
    published_at: '2023-10-01T15:17:39.833Z',
    updated_at: '2024-01-30T11:18:50.086Z',
    component: null,
    title: null,
    order: null,
    type: 'BitcoinSection',
    tag: 'b',
    json: {
      props: [
        {
          order: '0.1',
        },
      ],
    },
  });
  temp.push({
    id: uniqueId++,
    created_at: '2023-10-01T15:16:46.801Z',
    published_at: '2023-10-01T15:17:39.833Z',
    updated_at: '2024-01-30T11:18:50.086Z',
    component: null,
    title: null,
    order: null,
    type: 'HeroSection',
    tag: 'b',
    json: {
      props: [
        {
          order: '1',
        },
      ],
    },
  });
  temp.push({
    id: uniqueId++,
    created_at: '2023-10-01T15:16:46.801Z',
    published_at: '2023-10-01T15:17:39.833Z',
    updated_at: '2024-01-30T11:18:50.086Z',
    component: null,
    title: null,
    order: null,
    type: 'MarketplaceSection',
    tag: 'b',
    json: {
      props: [
        {
          order: '1.1',
        },
      ],
    },
  });
  temp.push({
    id: uniqueId++,
    created_at: '2023-10-01T15:15:46.801Z',
    published_at: '2023-10-02T15:17:39.833Z',
    updated_at: '2024-01-29T11:18:50.086Z',
    component: null,
    title: null,
    order: null,
    type: 'TelegramSection',
    tag: 'b',
    json: {
      props: [
        {
          order: '2',
        },
      ],
    },
  });
  temp.push({
    id: uniqueId++,
    created_at: '2023-10-01T15:16:46.801Z',
    published_at: '2023-10-01T15:17:39.833Z',
    updated_at: '2024-01-30T11:18:50.086Z',
    component: null,
    title: null,
    order: null,
    type: 'CommandCenterSection',
    tag: 'b',
    json: {
      props: [
        {
          order: '3',
        },
      ],
    },
  });
  temp.push({
    id: uniqueId++,
    created_at: '2023-10-01T15:16:46.801Z',
    published_at: '2023-10-01T15:17:39.833Z',
    updated_at: '2024-01-30T11:18:50.086Z',
    component: null,
    title: null,
    order: null,
    type: 'CryptoHunt',
    tag: 'b',
    json: {
      props: [
        {
          order: '4',
        },
      ],
    },
  });
  temp.push({
    id: uniqueId++,
    created_at: '2023-10-01T15:16:46.801Z',
    published_at: '2023-10-01T15:17:39.833Z',
    updated_at: '2024-01-30T11:18:50.086Z',
    component: null,
    title: null,
    order: null,
    type: 'JoinTheTroops',
    tag: 'b',
    json: {
      props: [
        {
          order: '5',
        },
      ],
    },
  });
  temp.push({
    id: uniqueId++,
    created_at: '2023-10-01T15:16:46.801Z',
    published_at: '2023-10-01T15:17:39.833Z',
    updated_at: '2024-01-30T11:18:50.086Z',
    component: null,
    title: null,
    order: null,
    type: 'GifCards',
    tag: 'b',
    json: {
      props: [
        {
          order: '6',
        },
      ],
    },
  });
  temp.push({
    id: uniqueId++,
    created_at: '2023-10-01T15:16:46.801Z',
    published_at: '2023-10-01T15:17:39.833Z',
    updated_at: '2024-01-30T11:18:50.086Z',
    component: null,
    title: null,
    order: null,
    type: 'AuditedBySection',
    tag: 'b',
    json: {
      props: [
        {
          order: '7',
        },
      ],
    },
  });
  temp.push({
    id: uniqueId++,
    created_at: '2023-10-01T15:15:46.801Z',
    published_at: '2023-10-02T15:17:39.833Z',
    updated_at: '2024-01-29T11:18:50.086Z',
    component: null,
    title: null,
    order: null,
    type: 'GameModeSection',
    tag: 'b',
    json: {
      props: [
        {
          order: '8',
        },
      ],
    },
  });
  temp.push({
    id: uniqueId++,
    created_at: '2023-10-01T15:15:46.801Z',
    published_at: '2023-10-02T15:17:39.833Z',
    updated_at: '2024-01-29T11:18:50.086Z',
    component: null,
    title: null,
    order: null,
    type: 'GameModeSection2',
    tag: 'b',
    json: {
      props: [
        {
          order: '9',
        },
      ],
    },
  });
  temp.push({
    id: uniqueId++,
    created_at: '2023-10-01T15:15:46.801Z',
    published_at: '2023-10-02T15:17:39.833Z',
    updated_at: '2024-01-29T11:18:50.086Z',
    component: null,
    title: null,
    order: null,
    type: 'HistorySection',
    tag: 'b',
    json: {
      props: [
        {
          order: '10',
        },
      ],
    },
  });
  temp.push({
    id: uniqueId++,
    created_at: '2023-10-01T15:15:46.801Z',
    published_at: '2023-10-02T15:17:39.833Z',
    updated_at: '2024-01-29T11:18:50.086Z',
    component: null,
    title: null,
    order: null,
    type: 'EtheriansSection',
    tag: 'b',
    json: {
      props: [
        {
          order: '11',
        },
      ],
    },
  });
  temp.push({
    id: uniqueId++,
    created_at: '2023-10-01T15:15:46.801Z',
    published_at: '2023-10-02T15:17:39.833Z',
    updated_at: '2024-01-29T11:18:50.086Z',
    component: null,
    title: null,
    order: null,
    type: 'NetherexSection',
    tag: 'b',
    json: {
      props: [
        {
          order: '12',
        },
      ],
    },
  });
  temp.push({
    id: uniqueId++,
    created_at: '2023-10-01T15:15:46.801Z',
    published_at: '2023-10-02T15:17:39.833Z',
    updated_at: '2024-01-29T11:18:50.086Z',
    component: null,
    title: null,
    order: null,
    type: 'WeaponSection',
    tag: 'b',
    json: {
      props: [
        {
          order: '13',
        },
      ],
    },
  });
  temp.push({
    id: uniqueId++,
    created_at: '2023-10-01T15:15:46.801Z',
    published_at: '2023-10-02T15:17:39.833Z',
    updated_at: '2024-01-29T11:18:50.086Z',
    component: null,
    title: null,
    order: null,
    type: 'PlatformSection',
    tag: 'b',
    json: {
      props: [
        {
          order: '14',
        },
      ],
    },
  });
  temp.push({
    id: uniqueId++,
    created_at: '2023-10-01T15:15:46.801Z',
    published_at: '2023-10-02T15:17:39.833Z',
    updated_at: '2024-01-29T11:18:50.086Z',
    component: null,
    title: null,
    order: null,
    type: 'HomeDailyNews',
    tag: 'b',
    json: {
      props: [
        {
          order: '15',
        },
      ],
    },
  });
  temp.push({
    id: uniqueId++,
    created_at: '2023-10-01T15:15:46.801Z',
    published_at: '2023-10-02T15:17:39.833Z',
    updated_at: '2024-01-29T11:18:50.086Z',
    component: null,
    title: null,
    order: null,
    type: 'HomeLastSection',
    tag: 'b',
    json: {
      props: [
        {
          order: '16',
        },
      ],
    },
  });
  temp.push({
    id: uniqueId++,
    created_at: '2023-10-01T15:15:46.801Z',
    published_at: '2023-10-02T15:17:39.833Z',
    updated_at: '2024-01-29T11:18:50.086Z',
    component: null,
    title: null,
    order: null,
    type: 'RoadmapLayout',
    tag: 'b',
    json: {
      props: [
        {
          order: '17',
        },
      ],
    },
  });
  temp.push({
    id: uniqueId++,
    created_at: '2023-10-01T15:15:46.801Z',
    published_at: '2023-10-02T15:17:39.833Z',
    updated_at: '2024-01-29T11:18:50.086Z',
    component: null,
    title: null,
    order: null,
    type: 'CompanyPartnersLayout',
    tag: 'b',
    json: {
      props: [
        {
          order: '18',
        },
      ],
    },
  });
  temp.push({
    id: uniqueId++,
    created_at: '2023-10-01T15:15:46.801Z',
    published_at: '2023-10-02T15:17:39.833Z',
    updated_at: '2024-01-29T11:18:50.086Z',
    component: null,
    title: null,
    order: null,
    type: 'JoinUsLayout',
    tag: 'b',
    json: {
      props: [
        {
          order: '19',
        },
      ],
    },
  });
  temp.sort(sortDate);

  return temp;
};
