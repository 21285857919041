import React, { useRef } from 'react';
import { useTranslation } from 'next-i18next';
import battery from '@/public/images/new-home/rover/battery.png';
import storage from '@/public/images/new-home/rover/storage.png';
import wheels from '@/public/images/new-home/rover/wheels.png';
import GameSection from '../BattleSection/GameSection';

export interface GameModeSectionProps {}

const GameModeSection: React.FunctionComponent<GameModeSectionProps> = () => {
  const { t } = useTranslation('newHome');
  const sectionRef = useRef(null);
  return (
    <div ref={sectionRef} className='px-4 sm:px-7  md:px-10 lg:px-16 xl:px-20 bg-[#000000]'>
      {/* <SectionHeader title='Game Modes' /> */}
      <div className=' md:overflow-x-auto lg:overflow-x-clip md:flex-row flex-col justify-between gap-2 lg:gap-5'>
        <GameSection
          name='The Hunt'
          hasYGradient
          title={t('newHome:rover.title')}
          subtitle={t('newHome:rover.subTitle')}
          description={t('newHome:rover.text')}
          buttonText={t('newHome:hero.button')}
          buttonLink='/start-mining'
          // secondaryButtonText={t('newHome:rover.button')}
          // secondaryButtonLink='/lite-game?mode=Play-to-Earn'
          video='images/new-home/game/rover.mp4'
          buttonTextColor='black'
          weapons={[
            { src: wheels, name: 'Storage' },
            { src: storage, name: 'Fuel' },
            { src: battery, name: 'Capsule' },
          ]}
          textColor='#47FEFF'
        />
      </div>
    </div>
  );
};

export default GameModeSection;
