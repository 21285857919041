'use client';

import React, { FC, useEffect, useState } from 'react';
import Image from 'next/image';
import MarketplaceCardLinkWrapper from '@/components/MiningCards/LinkWrapper';
import { Typography } from '@/components/Atoms';
import api from '@/api/services/marketplace';
import { useTranslation } from 'next-i18next';
import useTranslationStatus from '@/hooks/useTranslationStatus';

export interface CollectionCard2Props {
  className?: string;
  imgs?: string[];
  name?: string;
  link?: string;
  commingSoon?: boolean;
  collectionId?: string;
}

const CollectionCard2: FC<CollectionCard2Props> = ({ className, imgs = [], name = '', link = '/', commingSoon = false, collectionId }) => {
  const { t } = useTranslation('newHome');
  const { isReady } = useTranslationStatus();

  const [detailData, setDetailData] = useState<any>([]);
  const [showFullDescription, setShowFullDescription] = useState(false);

  useEffect(() => {
    if (collectionId) {
      api.getCollectionDetail({ id: collectionId }).then((res) => {
        setDetailData(res.data);
      });
    }
  }, [collectionId]);

  const truncatedDescription =
    detailData.description && detailData.description.length > 220 ? `${detailData.description.slice(0, 200)}...` : detailData.description;

  return (
    <MarketplaceCardLinkWrapper isLink={!commingSoon} href={link}>
      {/* Outer Wrapper with Equal Height */}
      <div className={`bg-black/[0.8] rounded-2xl h-full flex flex-col justify-between ${className}`}>
        <div className='relative w-full h-full'>
          {/* Gradient Background */}
          <div
            className='absolute left-0 w-full h-full rounded-2xl'
            style={{
              background: 'radial-gradient(circle at top, rgba(2,42,52,0.8) 0%, rgba(0,0,0,0.6) 50%, rgba(0,0,0,0) 100%)',
            }}
          />

          {/* Card Content */}
          <div className='relative pt-10 px-10 flex-1 h-[230px]'>
            <Typography Component={'p'} size='s20ToS14'>
              {isReady && t('newHome:weapons.collection')}
            </Typography>
            <Typography
              isBold
              size='s36ToS22'
              Component={'h5'}
              className={`lg:text-[38px] transition-colors flex items-center gap-2 duration-300 leading-9 mb-3 ${
                commingSoon ? 'opacity-30' : 'group-hover:text-[#25D6DF]'
              }`}
            >
              {name}
              {commingSoon && <span className='text-lg font-semibold'>Coming soon</span>}
            </Typography>

            <Typography size='s16ToS12'>
              {showFullDescription ? detailData.description : truncatedDescription}
              {detailData.description && detailData.description.length > 200 && (
                <span className='text-[#47FEFF] hover:text-[#25D6DF] cursor-pointer ml-2 underline'>
                  {isReady && t('newHome:weapons.readMore')}
                </span>
              )}
            </Typography>
          </div>

          {/* Card Images */}
          <div className='relative flex flex-col px-10 pb-6 mt-11'>
            <div className={`relative z-0 aspect-w-8 aspect-h-5 ${!commingSoon && 'transition-transform duration-300 group-hover:scale-[1.02]'}`}>
              <Image
                className={`object-cover rounded-xl ${commingSoon && 'opacity-30'}`}
                src={imgs[0]!}
                sizes='(max-width: 600px) 480px, 33vw'
                layout='fill'
                priority
                alt='Main Image'
              />
            </div>
            <div className='grid grid-cols-3 gap-3 mt-3'>
              {[1, 2, 3].map((index) => (
                <div key={index} className={`relative w-full ${!commingSoon && 'transition-transform duration-300 group-hover:scale-[1.02]'}`}>
                  <Image
                    className={`object-fill rounded-xl ${commingSoon && 'opacity-30'}`}
                    src={imgs[index]!}
                    width={500}
                    height={500}
                    sizes='150px'
                    priority
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </MarketplaceCardLinkWrapper>
  );
};

export default CollectionCard2;
