/* eslint-disable import/order */
// todo: multi languge for text
/* eslint-disable sonarjs/no-duplicate-string */
import { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Typography } from '@/components/Atoms';
import DynamicComponent from '@/components/DynamicComponent';
import CustomCard from '@/components/Molecules/CustomCard';
import { ArrowDownNew } from '@/components/Svg';
import LandrockerLogoLight from '@/components/Svg/home/LandrockerLogoLight';
import SwapWidget from '@/components/uniSwapWidget/components/SwapWidget';
import useAuthStatuses from '@/hooks/authentication/useAuthStatuses';
import useLocalStorage from '@/hooks/useLocalStorage';
import { useAppKit } from '@reown/appkit/react';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useRouter } from 'next/router';
import { useMediaQuery } from 'react-responsive';
import { useAccount } from 'wagmi';
import dynamic from 'next/dynamic';

const DynamicSwapWidget = dynamic(() => import('@/components/uniSwapWidget/components/SwapWidget'), {
  ssr: false,
});

const Home = ({ data }) => {
  const [verifyCode, setVerifyCode] = useLocalStorage('verifyCode-v1', 'aaa');
  const { isLogin } = useAuthStatuses();
  const router = useRouter();
  const { open } = useAppKit();
  const { isConnected, address } = useAccount();
  const { t } = useTranslation('home');
  const [isOpen, setIsOpen] = useState(false);
  const isDesktop = useMediaQuery({
    query: '(min-width: 1024px)',
  });

  useEffect(() => {
    if (isDesktop) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [isDesktop]);

  useEffect(() => {
    if (router.query.code !== undefined) {
      //@ts-ignore
      localStorage.setItem('invite-code', router.query.code);
      sessionStorage.setItem('invite-code', String(router.query.code));
    }
  }, [router]);

  useEffect(() => {
    if (typeof router.query?.code === 'string' && router.query?.code.length >= 8) {
      setVerifyCode(router.query?.code);
    }
  }, [router]);

  useEffect(() => {
    if (isLogin) {
      setVerifyCode('');
    }
  }, [isLogin]);

  const handleClickSwap = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className=''>
      <DynamicComponent />
      <div className={`transition-all ease-in-out fixed w-[300px] h-[300px] top-[200px] z-[11] ${isOpen ? 'right-[10px]' : '-right-[300px]'}`}>
        <div className='relative'>
          <CustomCard
            headerLeftIcon={<LandrockerLogoLight className='w-[20px] h-[20px] md:w-[20px] md:h-[20px] 2xl:w-[28px] 2xl:h-[28px]' />}
            isHeader
            showMoreLink='https://help.landrocker.io/economy-and-earning/acquiring-lrt-on-uniswap'
            isShowMore={`${t('home:TOP_SECTION.buySection.how_to_buy')}?`}
            headerTitle={t('home:newHome.buylrt')}
            showMoreColor='#47FEFF'
          >
            <DynamicSwapWidget />
          </CustomCard>
          <div
            className='w-[50px] h-[50px] bg-[#1B1C22] absolute top-[50px] -left-[50px]  rounded-tl-full rounded-bl-full  flex justify-start items-center pl-2 -z-[1] cursor-pointer gap-1'
            onClick={handleClickSwap}
          >
            <div className={`${isOpen ? '-rotate-90' : 'rotate-90'} transition-all animate-pulse`}>
              <ArrowDownNew />
            </div>
            <Typography isBold size='s14ToS12'>
              LRT
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export const getStaticProps = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale ?? 'en')), // Preload 'newHome' namespace
    },
    revalidate: 60,
  };
};

export default Home;
