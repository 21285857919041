import Image from 'next/image';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { Typography } from '@/components/Atoms';
import CommanderImage from '@/public/images/new-home/cc-image.png';
import CommandCenterTasksHome from './_tasks';
import styles from './CommandCenterSection.module.scss';
import classNames from 'classnames';

const CommandCenterSection = () => {
  const { t } = useTranslation('newHome');
  const router = useRouter();
  const Button = (
    <button onClick={() => router.push('/command-center')} className={classNames(styles.mainBtn, 'button-hover-effect')}>
      {t('newHome:commandCenter.button')}
    </button>
  );
  return (
    <div
      className={styles.commandCenterContainer}
      style={{
        background: 'linear-gradient(176deg, rgba(0,0,0,1) 0%, rgba(2,42,52,1) 50%, rgba(0,0,0,1) 100%)',
      }}
    >
      <div className='hidden lg:block lg:col-span-3 -mb-[6px]'>
        <Image src={CommanderImage} alt='commander' />
      </div>
      <div className='col-span-12 lg:col-span-9 xl:col-span-9'>
        <div className='flex flex-col md:justify-start justify-center md:items-start w-full h-full'>
          <div className='flex justify-between items-center w-full'>
            <div className='flex flex-col items-start mb-2'>
              <Typography className='font-bold leading-8' Component={'h2'} size='s40ToS24'>
                {t('newHome:commandCenter.title')}
              </Typography>
              <Typography Component={'p'} size='s20ToS16' className='text-[#C9C5CA]'>
                Your ultimate mission hub!
              </Typography>
            </div>
            <div className='hidden md:block my-2'>{Button}</div>
          </div>
          <Typography className='text-start text-[#C9C5CA]' Component={'p'} size='s16ToS14'>
            {/* {t('newHome:commandCenter.description')} */}
            Complete high-reward commands, earn LRT, and mine planets for valuable materials. Swap what you gather for real crypto and experience true
            Play-to-Earn excitement. Climb the leaderboard and boost your earnings in the LandRocker universe!
          </Typography>
          <CommandCenterTasksHome />
        </div>
      </div>
      <div className=' block col-span-12 md:hidden xl:col-span-2 min-w-[250px] mt-3 mb-4 '>{Button}</div>
    </div>
  );
};

export default CommandCenterSection;
