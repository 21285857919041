import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import classNames from 'classnames';
import Image from 'next/image';
import YoutubeVideoPlayer from '@/components/TokenSale/Header/VideoPlayer/YoutubeVideoPlayer';
import VideosStyles from '../Videos.module.scss';

interface IProps {
  posterUrl: string;
  url: string;
  isCustomUrl?: boolean;
  className?: string;
  customOnClick?: () => void;
}

const DailyNewsVideo = ({ posterUrl, url, isCustomUrl, className, customOnClick }: IProps) => {
  const [playing, setPlaying] = useState(false);
  const backgroundUrl = `${posterUrl}`;

  return (
    <div className={classNames('relative rounded-lg overflow-hidden w-full ', className)}>
      {playing ? (
        !isCustomUrl ? (
          <YoutubeVideoPlayer className={classNames('h-full w-full')} url={url} autoPlay={true} />
        ) : (
          <ReactPlayer
            onEnded={() => setPlaying(false)}
            url={url}
            controls={true}
            playing={true}
            width='100%'
            height='100%'
            config={{
              file: {
                attributes: {
                  poster: backgroundUrl,
                },
              },
            }}
          />
        )
      ) : (
        <div>
          <div className={`${VideosStyles.circle}`} onClick={customOnClick ? customOnClick : () => setPlaying(!playing)}>
            <div className={VideosStyles.triangle} />
          </div>
          <Image src={backgroundUrl} objectFit='cover' layout='fill' alt='background-url' />
        </div>
      )}
    </div>
  );
};

export default DailyNewsVideo;
