import React, { useEffect, useState } from 'react';
import api from '@/api/services/achievements';
import TaskCenterCardList2 from '@/components/Achievements/CampaignCards/components/_taskCardList2';

export interface CommandCenterTasksHomeProps {}

const CommandCenterTasksHome: React.FunctionComponent<CommandCenterTasksHomeProps> = () => {
  const [items, setItems] = useState([]);
  const getData = () => {
    api
      .getAllTasks2({
        // for_test: isTest,
      })
      .then((res) => {
        setItems(res.data.data.Achievement.slice(0, 3));
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className='w-full text-left'>
      <TaskCenterCardList2 isHome items={items} />
    </div>
  );
};

export default CommandCenterTasksHome;
