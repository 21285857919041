import { Typography } from '@/components/Atoms';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import React from 'react';
import MpCardList from './mpCardList';

export interface MarketplaceSectionProps {}

const MarketplaceSection: React.FunctionComponent<MarketplaceSectionProps> = () => {
  const { t } = useTranslation('newHome');
  return (
    <div
      className='px-4 sm:px-7 md:px-10 lg:px-16 xl:px-20 py-20 lg:py-[70px]'
      style={{
        backgroundImage: 'url(/images/new-home/mp-bg.webp)',
      }}
    >
      <div className='flex justify-between items-end mb-6 lg:mb-10'>
        <div>
          <Typography Component={'h2'} isBold className='text-[24px] md:text-[38px] xl:text-[50px]'>
            {t('newHome:mp.title')}
          </Typography>
          <Typography size='s18ToS14' Component={'p'} className='md:w-3/4'>
            {t('newHome:mp.description')}
          </Typography>
        </div>
        <Link href={'https://marketplace.landrocker.io/'}>
          <div className='py-2 px-4 border bg-opacity-30 bg-[#ffffff15] min-w-max rounded-lg cursor-pointer'>
            <Typography size='s16ToS14' className='line-clamp-1 max-w-max'>
              {t('newHome:weapons.goToMarketplace')}
            </Typography>
          </div>
        </Link>
      </div>
      <MpCardList />
    </div>
  );
};

export default MarketplaceSection;
