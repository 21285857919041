import React from 'react';
import { useTranslation } from 'next-i18next';
import CustomCardWithDesc from '@/components/Molecules/CustomCardWithDesc';
import Wrapper from '@/components/TokenSale/Wrapper';
import { ParseSentence } from '@/utils';
import SectionHeader from '../../Molecules/SectionHeader';
import styles from './BuyLRTReason.module.scss';
import 'swiper/css';
import 'swiper/css/pagination';
import StaticData from './staticData';
import HomeGifCard from '@/components/Molecules/HomeGifCard';

const PlatformSection = () => {
  const { t } = useTranslation('newHome');
  const contents = StaticData();

  return (
    <div className='pt-20 pb-28 px-4 sm:px-7 md:px-10 lg:px-16 xl:px-20 bg-[#000000]'>
      <SectionHeader title={t('newHome:platform.title')} subTitle={t('newHome:platform.title2')} />
      <div className='flex flex-wrap justify-around'>
        {contents.map((card, index) => (
          <HomeGifCard key={index} {...card} className='max-w-[400px] !xl:max-w-[500px]' />
        ))}
      </div>
    </div>
  );
};

export default PlatformSection;
