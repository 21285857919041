import React from 'react';
import { useTranslation } from 'next-i18next';
import { Typography } from '@/components/Atoms';
import { useResponsive } from '@/hooks';
import mobileImage from '@/public/images/new-home/last-mob.jpg';
import backgroundImage from '@/public/images/new-home/last.jpg';
import SectionContainer from '@/components/Molecules/SectionContainer';

export interface HomeLastSectionProps {}

const HomeLastSection: React.FunctionComponent<HomeLastSectionProps> = () => {
  const { t } = useTranslation('newHome');
  const { isDesktop1024 } = useResponsive();

  return (
    <SectionContainer
      backgroundImage={backgroundImage}
      mobileImage={mobileImage}
      contentPosition='right'
      hasAnimation={false}
      descColor='#ECE7BE'
      titleStyle={{
        background: 'linear-gradient(90deg, #999855, #B17A3C)' /* Adjust the colors and angle as needed */,
        WebkitTextFillColor: 'transparent',
        WebkitBackgroundClip: 'text',
        backgroundClip: 'text',
        textFillColor: 'transparent',
      }}
      secondContent={
        <div className='flex flex-col gap-y-6 pt-24 md:pt-0 py-20 lg:py-28'>
          <div>
            <Typography className='leading-4' size='s28ToS18'>
              {t('newHome:parallel.above')} {/* Translated text for "Real-World Parallels:" */}
            </Typography>
            <Typography Component={'h3'} size='s40ToS24' className='font-bold'>
              {t('newHome:parallel.title')} {/* Translated text for "A Game with Purpose" */}
            </Typography>
          </div>
          <div>
            <Typography Component={'h3'} size='s28ToS18' className='font-bold'>
              {t('newHome:parallel.draw')} {/* Translated text for "Drawing Parallels with Real Issues:" */}
            </Typography>
            <Typography className='text-[#C9C5CA]' size='s18ToS14'>
              {t('newHome:parallel.drawDesc')} {/* Translated text for the description about real-world challenges */}
            </Typography>
          </div>
          <div>
            <Typography Component={'h3'} size='s28ToS18' className='font-bold'>
              {t('newHome:parallel.action')} {/* Translated text for "Inspiration for Action:" */}
            </Typography>
            <Typography className='text-[#C9C5CA]' size='s18ToS14'>
              {t('newHome:parallel.actionDesc')} {/* Translated text for the description about inspiring action */}
            </Typography>
          </div>
        </div>
      }
      backgroundPosition={isDesktop1024 ? 'top' : 'left'}
    />
  );
};

export default HomeLastSection;
