import useTranslationStatus from '@/hooks/useTranslationStatus';
import { useTranslation } from 'next-i18next';

const StaticData = () => {
  const { t } = useTranslation('newHome');
  const { isReady } = useTranslationStatus();

  return isReady
    ? [
        {
          title: t('newHome:platform.community'), // Fetches the translated title for community
          description: t('newHome:platform.communityDesc'), // Fetches the translated description for community
          gif: 'images/new-home/gif/people.gif',
          video: 'images/new-home/webm/people.webm',
        },
        {
          title: t('newHome:platform.empowerment'), // Fetches the translated title for empowerment
          description: t('newHome:platform.empowermentDesc'), // Fetches the translated description for empowerment
          gif: 'images/new-home/gif/game.gif',
          video: 'images/new-home/webm/game.webm',
        },
      ]
    : [];
};

export default StaticData;
