import React from 'react';
import Link from 'next/link';
import { Typography } from '@/components/Atoms';
import LoadingBalls from '@/components/Common/LoadingBalls';
import DailyNewsVideo from '@/components/Press/Videos/components/DailyNewsVideo';
import { IDailyNeewsVideosData } from '@/types/TokenSale/res';
import styles from './VideosOfInfluencerItem.module.scss';

const VideosOfDailyNews = ({ item }: { item: IDailyNeewsVideosData }) => {
  if (!item) {
    return <LoadingBalls />;
  }
  return (
    <>
      <div className={styles.container}>
        <Link href={item.EmbedLink || item.VideoLink}>
          <a target='_blank' rel='noopener noreferrer' className='text-center w-full'>
            <DailyNewsVideo
              isCustomUrl={!item.EmbedLink}
              posterUrl={item.ImageLink}
              url={item.EmbedLink || item.VideoLink}
              className='w-full aspect-video'
            />
            <div className={styles.youtubeUrlContainer}>
              <Typography size='s20ToS16' isBold className='underline text-[16px] ps-1'>
                {item.Title}
              </Typography>
            </div>
          </a>
        </Link>
      </div>
    </>
  );
};

export default VideosOfDailyNews;
