import { IData } from '@/types/TokenSale/res';
import React from 'react';
import LoadingBalls from '../Common/LoadingBalls';
import CompanyPartnersLayout from '../Home/CompanyPartnersLayout';
import HomeDailyNews from '../Home/DailyNews';
import JoinUsLayout from '../Home/JoinUsLayout';
import RoadmapLayout from '../Home/RoadmapLayout';
import CustomDivider from '../Molecules/CustomDivider';
import {
  CommandCenterSection,
  EtheriansSection,
  GameModeSection,
  GameModeSection2,
  GifCards,
  HeroSection,
  HistorySection,
  HomeLastSection,
  JoinTheTroops,
  NetherexSection,
  PlatformSection,
  TelegramSection,
  WeaponSection,
  BitcoinSection,
  MarketplaceSection,
} from '../NewHome';
import AuditedBySection from '../NewHome/AuditedBySection';
import CryptoHunt from '../NewHome/CryptoHunt';
import { homePageData } from './HomePageData';

const Components = {
  // HeroSection,
  BitcoinSection,
  TelegramSection,
  CommandCenterSection,
  CryptoHunt,
  JoinTheTroops,
  GifCards,
  AuditedBySection,
  GameModeSection,
  MarketplaceSection,
  GameModeSection2,
  HistorySection,
  EtheriansSection,
  NetherexSection,
  // WeaponSection,
  PlatformSection,
  HomeDailyNews,
  HomeLastSection,
  RoadmapLayout,
  CompanyPartnersLayout,
  JoinUsLayout,
};

const DynamicComponent = () => {
  const temp = homePageData();

  return (
    <div className='flex flex-col'>
      {!temp && (
        <div className='mt-[30px]'>
          <LoadingBalls />
        </div>
      )}
      {temp.map((item: IData, index: number) => {
        let props = item?.json.props[0];
        props.tag = item.tag || null;
        const Component = Components[item.type];
        const showComponent = typeof Components[item.type] === 'function';
        return (
          <div key={item.id}>
            {showComponent ? (
              <>
                <Component {...props} />
                {index === temp.length - 1 ? null : <CustomDivider />}
              </>
            ) : null}
            {index === temp.length - 1 ? null : <CustomDivider />}
          </div>
        );
      })}
    </div>
  );
};
export default React.memo(DynamicComponent);
